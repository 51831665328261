<template>
  <div class="d-flex cart">
    <get-list />
    <cart-list class="cart__list" />
  </div>
</template>

<script>
import GetList from '@/components/cart/GetList.vue'
import CartList from '@/components/cart/CartList.vue'

export default {
  name: 'Cart',
  components: {
    'get-list': GetList,
    'cart-list': CartList
  }
}
</script>

<style lang="scss" scoped>
.cart {
  padding: 0 2.604vw 0 6.667vw;
  flex-grow: 1;

  &__list {
    flex-grow: 1;
  }
}
</style>
