<template>
  <div class="position-relative cart-list">
    <p v-if="!cartList.length && lang === 'hk'" class="cart-list__no-product-tip">你的購物籃沒有任何產品。</p>
    <p v-if="!cartList.length && lang === 'en'" class="cart-list__no-product-tip cart-list__no-product-tip--en">You do not have any products on your shopping cart.</p>
    <vue-scroll ref="vs" :ops="ops" @handle-scroll="handleScroll" @handle-resize="handleResize">
      <div class="cart-list__container">
        <div
          v-for="item in cartList"
          :key="item.id"
          class="d-flex cart-list__item"
        >
          <div class="cart-list__item-img" :style="{backgroundImage: `url(${item.pic})`}">
            <img v-if="item.new_lower_price === 'Y' && lang === 'hk'" src="@/assets/img/product/lowerprice-sticker.svg">
            <img v-if="item.new_lower_price === 'Y' && lang !== 'hk'" src="@/assets/img/product/lower-price-sticker-en.svg">
            <img v-if="item.new_product === 'Y' && lang === 'hk'" src="@/assets/img/product/newproduct-sticker.svg">
            <img v-if="item.new_product === 'Y' && lang !== 'hk'" src="@/assets/img/product/newproduct-sticker-en.svg">
          </div>
          <div class="flex-column cart-list__item-info-block">
            <p class="font-bold cart-list__item-brand">{{ item.brand }}</p>
            <p v-if="lang === 'hk'" class="cart-list__item-name">{{ item.name }}</p>
            <p v-else class="cart-list__item-name cart-list__item-name--en">{{ item.en_name }}</p>
            <p class="font-bold cart-list__item-price">${{ item.price }}</p>
            <p class="cart-list__item-tip">{{ item.note }}</p>
          </div>
          <div class="d-flex items-center cart-list__item-count-box">
            <div class="cart-list__item-count-minus" @click="onMinusClick(item.id)">-</div>
            <div>{{ item.count }}</div>
            <div class="cart-list__item-count-plus" @click="onPlusClick(item.id)">+</div>
          </div>
          <div class="cart-list__item-delete" @click="REMOVE_PRODUCT(item.id)"></div>
        </div>
      </div>
    </vue-scroll>
     <div v-if="tipNeedShow" class="d-flex justify-center items-center cart-list__scroll-tip-block">
      <img src="@/assets/img/product/scroll.svg">
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import vuescroll from 'vuescroll'

export default {
  name: 'CartList',
  components: {
    'vue-scroll': vuescroll
  },
  data () {
    return {
      tipNeedShow: false,
      ops: {
        rail: {
          background: '#9b9b9b',
          opacity: 0.56,
          size: '7px',
          specifyBorderRadius: '10px'
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: '#707070',
          specifyBorderRadius: true,
          size: '7px'
        }
      }
    }
  },
  computed: {
    ...mapState('lang', ['lang']),
    ...mapState('cart', ['cartList'])
  },
  mounted () {
    this.$nextTick(() => {
      const t = this.$refs.vs.classHooks.vBarVisible
      this.tipNeedShow = t
    })
  },
  methods: {
    ...mapMutations('cart', ['UPDATE_COUNT', 'REMOVE_PRODUCT']),
    ...mapMutations('screensaver', ['RESET_TIMER']),
    onPlusClick (id) {
      this.UPDATE_COUNT({ id, type: 'plus' })
    },
    onMinusClick (id) {
      if (this.cartList.find(item => parseInt(item.count) > 1)) {
        this.UPDATE_COUNT({ id, type: 'minus' })
      }
    },
    handleScroll (vertical) {
      this.RESET_TIMER()
      this.tipNeedShow = vertical.process !== 1
    },
    handleResize () {
      this.$nextTick(() => {
        this.tipNeedShow = this.$refs.vs.classHooks.hasVBar
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-list {
  height: 71.1111vh;
  padding-left: 6.6667vw;

  &__container {
    padding-right: 2.5vw;
  }

  &__no-product-tip {
    font-size: 1.771vw;
    letter-spacing: 0.08854vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &--en {
      font-size: 1.5625vw;
      letter-spacing: 0.078125vw;
      line-height: normal;
    }
  }

  &__item {
    width: 100%;
    border-bottom: 1px solid $deep-gray;
    padding-bottom: 1.574vh;
    margin-bottom: 1.574vh;
  }

  &__item-img {
    width: 11.46vw;
    height: 11.46vw;
    @include bg-cover;
  }

  &__item-info-block {
    margin-left: 1.041667vw;
  }

  &__item-brand {
    font-size: 1.875vw;
    line-height: 1;
    letter-spacing: -0.05625vw;
  }

  &__item-name {
    font-size: 1.354vw;
    line-height: 1.15;
    max-width: 16.667vw;
    margin-top: 1.4815vh;

    &--en {
      font-size: 1.25vw;
      line-height: 1.17;
    }
  }

  &__item-price {
    font-size: 2.8125vw;
    line-height: normal;
    letter-spacing: -0.05625vw;
    margin-top: auto;
  }

  &__item-tip {
    font-size: 0.83333vw;
  }

  &__item-count-box {
    margin-left: auto;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.3333vw;
      height: 3.3333vw;
      border: 1px solid $deep-gray;
      font-size: 1.823vw;
    }
  }

  &__item-delete {
    width: 2.734375vw;
    height: 5.5556vh;
    @include bg-cover;
    background-image: url('~@/assets/img/cart/delete-item.svg');
    margin-left: 1.5625vw;
    align-self: center;
  }

  &__scroll-tip-block {
    position: absolute;
    padding-top: 1.574vh;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
  }
}
</style>
