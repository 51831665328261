<template>
  <div class="position-relative get-list">
    <div class="d-flex get-list__info-block">
      <div class="d-flex justify-center get-list__heart">
        <span class="font-bold get-list__heart-num">{{ cartList.length }}</span>
      </div>
      <div class="get-list__text-content">
        <p
          class="font-bold get-list__title"
          :class="{'get-list__title--en': lang !== 'hk'}"
        >
          {{ title }}
        </p>
        <p
          v-for="(text, idx) in textList"
          :key="idx"
          class="get-list__text"
          :class="{'font-bold get-list__text--en': lang !== 'hk'}"
        >
          {{ text }}
        </p>
      </div>
    </div>
    <div v-if="!isGet" class="get-list__btn-block">
      <button
        class="font-bold get-list__btn"
        :class="{'get-list__btn--en': lang !== 'hk', 'get-list__btn--active': cartList.length}"
        @click="onGetListClick"
      >
        {{ buttonText }}
      </button>
    </div>
    <div v-else class="get-list__qrcode-block">
      <div
        class="get-list__qrcode"
        :class="{'get-list__qrcode--en': lang === 'en'}"
        :style="{backgroundImage: `url(${qrcode})`}"
      ></div>
      <p
        class="font-bold get-list__qrcode-text"
        :class="{'get-list__qrcode-text--en': lang !== 'hk'}"
      >
        {{ qrcodeText }}
      </p>
    </div>
    <div class="get-list__delete-block">
      <div
        v-show="cartList.length"
        class="d-flex items-center justify-center get-list__delete-btn"
        @click="REMOVE_PRODUCT_ALL"
      >
        <div class="get-list__delete-icon"></div>
        <span class="font-bold">{{ deleteText }}</span>
      </div>
       <p
          class="get-list__tip"
          :class="{'get-list__tip--en': lang !== 'hk'}"
        >
          {{ tipText }}
        </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'GetList',
  data () {
    return {
      isGet: false
    }
  },
  computed: {
    ...mapState('lang', ['lang']),
    ...mapState('cart', ['cartList', 'qrcode']),
    textList () {
      return this.lang === 'hk' ? ['點選下方按鈕獲取二維碼，', '即時於手機落單！'] : ['Click the button below,', 'get the QR code and order online now!']
    },
    title () {
      return this.lang === 'hk' ? '您的購物籃' : 'Your shopping cart'
    },
    buttonText () {
      return this.lang === 'hk' ? '獲取二維碼' : 'Get your QR code'
    },
    tipText () {
      return this.lang === 'hk' ? '產品資料及存貨狀況只供參考' : 'Product and stock information are for reference only.'
    },
    deleteText () {
      return this.lang === 'hk' ? '移除所有產品' : 'Remove all products'
    },
    qrcodeText () {
      return this.lang === 'hk' ? '掃瞄並於網上落單' : 'Scan the QR code and order online'
    }
  },
  watch: {
    cartList: {
      handler () {
        this.isGet = false
      }
    },
    lang: {
      handler () {
        this.isGet = false
      }
    }
  },
  methods: {
    ...mapMutations('cart', ['REMOVE_PRODUCT_ALL']),
    ...mapActions('api', ['fetchProductQrcode']),
    ...mapActions('gtagEvents', ['getQrcodeTrack', 'itemInOwnListTrack']),
    async onGetListClick () {
      if (this.cartList.length) {
        this.getQrcodeTrack({ lan: this.lang })

        this.cartList.forEach(item => {
          this.itemInOwnListTrack({ code: item.code, count: item.count, lan: this.lang })
        })

        await this.fetchProductQrcode({ list: { products: this.cartList }, lang: this.lang })
        this.isGet = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.get-list {
  padding-top: 2.2222vh;
  min-width: 25.41667vw;

  &__info-block {
    min-height: 20.2778vh;
  }

  &__heart {
    width: 3.5vw;
    height: 6.4815vh;
    @include bg-cover;
    background-image: url('~@/assets/img/cart/cart.svg');
    padding-top: 0.74vh;
    padding-left: 0.8854vw;
  }

  &__heart-num {
    font-size: 1.09375vw;
    color: $white;
  }

  &__text-content {
    margin-left: 1.26vw;
  }

  &__title {
    font-size: 2.91667vw;
    line-height: normal;
    margin-bottom: 1.9722vh;

    &--en {
      font-size: 2.1875vw;
      letter-spacing: -0.065625vw;
      margin-bottom: 1.1111vh;
    }
  }

  &__text {
    font-size: 1.3021vw;
    letter-spacing: 0.065vw;
    line-height: normal;

    &--en {
      font-size: 1.25vw;
      letter-spacing: -0.025vw;
    }
  }

  &__btn-block {
    padding-left: 2.65625vw;
  }

  &__qrcode-block {
    padding-left: 7.083333vw;
  }

  &__qrcode {
    width: 11.98vw;
    height: 11.98vw;
    @include bg-cover;
    margin-top: 2.7778vh;

    &--en {
      margin-top: 0;
    }
  }

  &__qrcode-text {
    font-size: 1.5625vw;
    line-height: normal;
    margin-top: 0.741vh;

    &--en {
      padding-left: 0;
      max-width: 15.625vw;
      text-align: center;
      line-height: 1.2;
      transform: translateX(-1.823vw);
      margin-top: 1.4815vh;
    }
  }

  &__btn {
    width: 20.8333vw;
    height: 13.52vh;
    border-radius: 3.75vw;
    background-color: #ddd;
    color: $white;
    border: none;
    font-size: 1.823vw;
    line-height: 1.4;
    padding: 0 5.8333vw;
    margin-top: 9.26vh;

    &--en {
      padding: 0 2.34375vw;
    }

    &--active {
      background-color: $black;
    }
  }

  &__delete-block {
    position: absolute;
    bottom: 9.537vh;
    width: 100%;
  }

  &__delete-btn {
    font-size: 1.875vw;
    line-height: 1.33;
    letter-spacing: -0.075vw;
    width: 100%;
    margin-bottom: 0.741vh;
  }

  &__delete-icon {
    width: 1.91667vw;
    height: 3.8889vh;
    @include bg-cover;
    background-image: url('~@/assets/img/cart/delet.svg');
    margin-right: 1.609375vw;
  }

  &__tip {
    font-size: 1.04167vw;
    color: $deep-gray;
    text-align: center;

    &--en {
      left: 0;
      letter-spacing: -0.0104vw;
    }
  }
}
</style>
